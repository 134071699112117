// import "./index.css";
// import { Button } from "react-bootstrap";
// import "./index.css";
// import { useAppStore } from "../AppStore";
// import { Link, useNavigate, useParams } from "react-router-dom";
// import { CgDetailsMore } from "react-icons/cg";
// import StoreProductDetails from "../StoreProductDetailView";
// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import AdminNavbar from "../AdminNavbar";

// const AdminStore = () => {
//     const [referrals, setReferrals] = useState([]);

//     useEffect(() => {
//       // Fetch the referral data from the backend API
//       axios.get('http://localhost:3000/api/referrals')
//         .then((response) => {
//           setReferrals(response.data);
//         })
//         .catch((error) => {
//           console.error('Error fetching data:', error);
//         });
//     }, []);
//     console.log(referrals)
 
//   React.useEffect(() => {
//     if (!sessionStorage.getItem('adminEmail')) {
//         window.location.href = '/admin-login'
//     }
// }, [])

//   return (
//     <>
//       <AdminNavbar />

//       <div className="App">
//       <h1>Referral</h1>
//       <table border="1" cellPadding="10" style={{ width: '80%', margin: '0 auto' }}>
//         <thead>
//           <tr>
//             <th>ID</th>
//             <th>Name</th>
           
//             <th>Product</th>
//             <th>Email</th>
//           </tr>
//         </thead>
//         <tbody>
//           {referrals.length > 0 ? (
//             referrals.map((referral,index) => (
//               <tr key={referral.id}>
//                 <td>{index+1}</td>
//                 <td>{referral.customerSalerName}</td>
//                 <td>{referral.pName}</td>
//                 <td>{referral.email}</td>
            
//               </tr>
//             ))
//           ) : (
//             <tr>
//               <td colSpan="4">Loading...</td>
//             </tr>
//           )}
//         </tbody>
//       </table>
//     </div>
//     </>
//   );
// };

// export default AdminStore;


import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTable, usePagination } from "react-table";
import { Pagination, Table } from "react-bootstrap";
import AdminNavbar from "../AdminNavbar";
import "./index.css";

const AdminStore = () => {
  const [referrals, setReferrals] = useState([]);

  useEffect(() => {
    // Fetch the referral data from the backend API
    axios
      .get("https:///vyaparapi.emedha.in/api/referrals")
      .then((response) => {
        setReferrals(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  React.useEffect(() => {
    if (!sessionStorage.getItem("adminEmail")) {
      window.location.href = "/admin-login";
    }
  }, []);

  // Columns for react-table
  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: (row, index) => index + 1,
      },
      {
        Header: "CustomerSalerName",
        accessor: "customerSalerName",
      },
      {
        Header: "Product",
        accessor: "pName",
      },
      {
        Header: "Email",
        accessor: "email",
      },
    ],
    []
  );

  // React-table hooks
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page, // Get current page rows
    canPreviousPage,
    canNextPage,
    pageIndex,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data: referrals,
    },
    usePagination // Add pagination plugin
  );

  return (
    <>
      <AdminNavbar />

      <div>
        <h1 className="mb-4">Referral</h1>

        <Table responsive striped bordered hover style={{ width: "100%", margin: "0 auto", backgroundColor: "transparent" }}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.length > 0 ? (
              page.map((row, index) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                    })}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="4">Loading...</td>
              </tr>
            )}
          </tbody>
        </Table>
        <div className="d-flex justify-content-center mt-3">
        <Pagination>
          <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
          <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />

          {/* Displaying Current Page */}
          {/* <Pagination.Item disabled>
            {`Page ${pageIndex + 1} of ${pageOptions.length}`}
          </Pagination.Item> */}

          <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
          <Pagination.Last onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage} />
        </Pagination>
        </div>

        <div className="d-flex justify-content-between mt-3">
          <select
            className="form-select w-auto"
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            style={{ width: "auto", margin: "0 auto" }}
          >
            {[10, 20, 30, 40, 50].map((size) => (
              <option key={size} value={size}>
                Show {size}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};

export default AdminStore;
