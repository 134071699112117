import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import CustomerDashboard from '../CustomerDashboard';
import AdminStore from '../../AdminStore/Users';
 
 

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: 'auto',
    color: theme.palette.text.secondary,
}));


export default function FullWidthGrid2() {

    return (
        <Box sx={{ flexGrow: 1, p: 0, mt: 2 }} className='ps-3 pe-3 pt-4' >
            {/* <CustomerDashboard></CustomerDashboard> */}
            <Grid container spacing={2}>
                <Grid sx={{ mt: 1 }} xs={12} md={12}>
                    <Item elevation={1}>
                         <AdminStore></AdminStore>
                    </Item>
                </Grid>
               
            </Grid>
           
        </Box>
    );
}